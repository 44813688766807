import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const purchaseOrderTableDataSlice = createSlice({
  name: "purchaseOrderTableData",
  initialState: [],
  reducers: {
    addItemToTableData: (state, action) => {
      state.push(action.payload);
    },
    updateTableRowValue: (state, action) => {
      console.log("kkkkkkkkkkkkkkkkkkkkkkk", action.payload);

      const {
        index,
        free,
        discAmt,
        qty,
        Cost,
        Tax1,
        Rate1,
        Rate2,
        exp_date,
        batch,
        TaxEnabled,
        exchangeRate,
      } = action.payload;
      state[index].free = free;
      state[index].discAmt = discAmt;
      state[index].qty = qty;
      state[index].Cost = Cost;
      state[index].Rate1 = Rate1;
      state[index].Rate2 = Rate2;
      state[index].exp_date = exp_date;
      state[index].batch = batch;
      state[index].total = qty * Cost - discAmt;
      state[index].UnitRateFC = (Cost / exchangeRate).toFixed(2);
      state[index].TotalFc = (
        TaxEnabled.Nam !== "YES"
          ? qty * Cost - discAmt + ((qty * Cost - discAmt) * Tax1) / 100
          : (qty * Cost) / exchangeRate
      ).toFixed(2);
      state[index].taxAmt =
        TaxEnabled.Nam !== "YES"
          ? ((qty * Cost - discAmt) * Tax1) / 100
          : (qty * Tax1 * Cost) / (100 + Tax1);
      state[index].netValue =
        TaxEnabled.Nam !== "YES"
          ? qty * Cost - discAmt + ((qty * Cost - discAmt) * Tax1) / 100
          : qty * Cost;
      if (Cost === 0 || Rate1 === 0) {
        state[index].perc = (0).toFixed(2);
      } else {
        state[index].perc = (((Rate1 - Cost) / Cost) * 100).toFixed(2);
      }
    },

    deleteItemFromTableData: (state, action) => {
      const index = state.findIndex(
        (item) => item.ItemNo === action.payload.ItemNo
      );
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    clearTableData: (state, action) => {
      return [];
    },
    // New reducer to replace the array with new data
    replaceTableData: (state, action) => {
      return action.payload; // Set the state to the new data
    },
  },
});

export const {
  addItemToTableData,

  deleteItemFromTableData,
  clearTableData,
  replaceTableData,

  updateTableRowValue,
} = purchaseOrderTableDataSlice.actions;
export default purchaseOrderTableDataSlice.reducer;
