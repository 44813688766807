import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import HomeIcon from "@mui/icons-material/Home";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useEffect } from "react";
import { baseUrl } from "config";
import {
  setCompanyInfo,
  setPrintrtTyp,
  setIsDrawerAlways,
  setCommonState,
} from "commonSlice/commonSlice";
import { redirectUrl } from "config";

const CardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.dark,
  overflow: "hidden",
  position: "relative",
  "&:before": {
    content: '""',
    zIndex: 0,
    position: "absolute",
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.secondary.main} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: "50%",
    top: -30,
    right: -100,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.secondary.main} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
    borderRadius: "50%",
    top: -140,
    right: -60,
  },
}));
function SalesLayOut() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const companyInfo = useSelector((state) => state.common.companyInfo);
  useEffect(() => {
    function getCompany() {
      axios
        .get(baseUrl + "/main/getCompanyDetails", { withCredentials: true })
        .then((res) => {
          dispatch(setCompanyInfo(res.data[0]));
        });
    }
    function getPrinter() {
      axios({
        method: "get",
        url: baseUrl + "/getPrinterNameAndOther",
        withCredentials: true,
      })
        .then((res) => {
          dispatch(setPrintrtTyp(res.data));
          dispatch(
            setIsDrawerAlways(
              res.data[3][0].DrawerStatus === "YES" ? true : false
            )
          );

          dispatch(
            setCommonState({
              isADDon: res.data[7][0]?.add_on === "YES" ? true : false,
              allKot: res.data[8][0]?.all_kot === "YES" ? true : false,
              taxUtilitiesListData: res.data[10],
              KotEnabled: res.data[11][0]?.KotEnabled === "YES" ? true : false,
            })
          );
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            redirectUrl();
          }
        });
    }
    getPrinter();
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleHomeClick = () => {
    navigate("/dashboard/default");
  };
  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullscreen(!isFullscreen);
  };

  return (
    <>
      <Box sx={{ width: "100%", background: "rgb(238, 242, 246)" }}>
        <Box
          sx={{
            padding: "5px 15px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            background: "#fff",
          }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {companyInfo ? (
            <Box sx={{ fontWeight: 600 }}>{companyInfo.Name}</Box>
          ) : (
            <Box sx={{ fontWeight: 600 }}>Company Name</Box>
          )}
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ fontSize: "2rem" }}
                aria-label="full screen"
                onClick={handleHomeClick}
              >
                <HomeIcon fontSize="2.5rem" />
              </IconButton>

              <IconButton
                sx={{ fontSize: "2rem" }}
                aria-label="full screen"
                onClick={handleFullscreenToggle}
              >
                {isFullscreen ? (
                  <FullscreenExitIcon fontSize="2.5rem" />
                ) : (
                  <FullscreenIcon fontSize="2.5rem" />
                )}
              </IconButton>
              {/* <AccountPop /> */}
            </Box>
          </Box>
        </Box>
        <CardWrapper>
          <Outlet />
        </CardWrapper>
      </Box>
    </>
  );
}
export default SalesLayOut;
