import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  customer: null,
  contact: 0,
  prevNo: 0,
  BatchNo: 0,
  invo: null,
  taxNo: 0,
  vatNo: 0,
  note:"",
  date: dayjs(),
  outlet: null,
  careOf: null,
  editMode: false,
  refDate: dayjs(),
  currency: { code: "" },
  exChangeRate: 0,
};
const purchaseOrderHeaderSlice = createSlice({
  name: "puchaseOrderHeader",
  initialState,
  reducers: {
    updateSelected: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetState: () => {
      // Reset the state to its initial/default values
      return initialState;
    },
  },
});

export const { updateSelected, resetState } = purchaseOrderHeaderSlice.actions;
export default purchaseOrderHeaderSlice.reducer;
