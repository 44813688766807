import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const purchaseImportHeaderSlice = createSlice({
  name: "puchaseImportHeaderSlice",
  initialState: {
    customer: null,
    contact: 0,
    prevNo: 0,
    BatchNo: 0,
    invo: null,
    taxNo: 0,
    vatNo: 0,
    date: dayjs(),
    refDate: dayjs(),
    currency: { code: "" },
    exChangeRate: 0,
    outlet: null,
    careOf: null,
    editMode: false,
    isCostShow: false,
    
  },
  reducers: {
    updateSelected: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetState: () => {
      // Reset the state to its initial/default values
      return {
        customer: null,
        contact: 0,
        prevNo: 0,
        BatchNo: 0,
        invo: null,
        taxNo: 0,
        vatNo: 0,
        date: dayjs(),
        outlet: null,
        careOf: null,
        godownWise: false,
      };
    },
  },
});

export const { updateSelected, resetState } = purchaseImportHeaderSlice.actions;
export default purchaseImportHeaderSlice.reducer;
