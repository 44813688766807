import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { IconSearch } from "@tabler/icons";
import { useSelector, useDispatch } from "react-redux";
import { addItemToTableData } from "views/Sales-Sections/salesReturn/slice/tableDataSlice";
import {
  addItemToTableData as addItemToPos,
  selectedRowOrCurrentRow,
  updateBatch,
  updateQtyAndTotal,
} from "../salesPos/slice/tableDataPosSlice";
import { redirectUrl, baseUrl } from "config";

import MultiUnitWindow from "../salesReturn/components/MultiUnitWindow";
import BatchWindowPos from "../salesReturn/components/BatchWindowPos";
import BatchWindow from "../salesPos/components/BatchWindow";
import MultiUnitWindowPos from "../salesPos/components/MultiUnitWindowPos";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackBar } from "context/snackbarContext";
import "./addOnBtn.css";

const colors = [
  "#2E8B57", // SeaGreen
  "#8B0000", // DarkRed
  "#4B0082", // Indigo
  "#556B2F", // DarkOliveGreen
  "#8B008B", // DarkMagenta
  "#FF4500", // OrangeRed
  "#483D8B", // DarkSlateBlue
  "#006400", // DarkGreen
  "#8B4513", // SaddleBrown
  "#4682B4", // SteelBlue
];

// function DrawerItems({ typ, allProduct, expiry, batchWise }) {
function DrawerItems({ typ, expiry, batchWise }) {
  const shadowInside = {
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
  };
  const shadowOutSide = {
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
  };
  const theme = useTheme();
  const dispatch = useDispatch();
  const allProductsData = useSelector(
    (state) => state.ProductsData?.data?.result
  );

  const isAddOnAlways = useSelector((state) => state.common.isADDon);

  // const [allProducts, setAllProducts] = useState(allProductsData || []);
  // const [draweData, setDrawerData] = useState(allProductsData || []);
  const [allProducts, setAllProducts] = useState(allProductsData);
  const [draweData, setDrawerData] = useState(allProductsData);
  const [isProductOrAddon, setIsProductOrAddon] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryMenuItems, setCategoryMenuItems] = useState();
  const [currProduct, setCurrProduct] = useState(null);
  const [modalTyp, setModalTyp] = useState(null);
  const itemsPerPage = 198;
  const [itemQuantities, setItemQuantities] = useState([]);
  const tableDataPos = useSelector((state) => state.tableDataPos);
  const netTotal = useSelector((state) => state.discAndTaxPos.netTotal);
  const [addOnItems, setAddOnItems] = useState([]);
  const taxUtilitiesListData = useSelector(
    (state) => state.common.taxUtilitiesListData
  );

  const { showSnackbar } = useSnackBar();
  useEffect(() => {
    getCategory("PRODUCT GROUP", setCategoryMenuItems);
    getCategory("ADD_ON", setAddOnItems);
  }, []);

  // useEffect(() => {
  //   setDrawerData(allProduct);
  //   setAllProducts(allProduct);
  // }, [allProduct]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  // const itemsToRender = draweData?.slice(startIndex, endIndex);
  const itemsToRender = allProductsData?.slice(startIndex, endIndex);
  const regex = /^[A-Z]/;

  // const handleSearchField = () => {
  //   var updatedList = [...allProducts];

  //   updatedList = updatedList.filter((item) => {
  //     return item.ItemNameTextField.toLowerCase().includes(
  //       searchName.toLowerCase()
  //     );
  //   });

  //   setDrawerData(updatedList);
  // };
  const handleSearchField = (value) => {
    setSearchName(value);
    var updatedList = [...allProducts];

    updatedList = updatedList.filter((item) => {
      return item.ItemNameTextField.toLowerCase().includes(value.toLowerCase());
    });

    setDrawerData(updatedList);
  };

  const getCategory = (name, stat) => {
    setDrawerData(allProductsData);
    setAllProducts(allProductsData);
    axios({
      method: "post",
      url: baseUrl + "/getCategoryOrSubcategory",
      data: {
        typ: name,
      },
      withCredentials: true,
    })
      .then((res) => {
        // to show all the products we need all menu option
        stat === setCategoryMenuItems
          ? stat([
              {
                Typ: "All",
              },

              ...res.data,
            ])
          : stat([...res.data]);
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          redirectUrl();
        }
      });
  };

  const handelCategory = (value) => {
    if (value === "All") {
      setDrawerData(allProducts);
    } else {
      const filtered = allProducts.filter((name) => name.Size === value);
      setDrawerData(filtered);
    }
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const clearField = () => {
    setSearchName("");
    setDrawerData(allProducts);
  };

  const handleAddAddOns = (item) => {
    const existingItemIndex = tableDataPos.findIndex(
      (items) => items.selectedRow === true
    );

    if (existingItemIndex !== -1) {
      const duplicateCheck = tableDataPos[existingItemIndex].batch.find(
        (data) => data === item.Typ
      );

      if (duplicateCheck) {
        showSnackbar(
          "error",
          `${item.Typ} already exist in ${tableDataPos[existingItemIndex].ItemNameTextField}`
        );
      } else {
        dispatch(
          updateBatch({
            index: existingItemIndex,
            batch: item.Typ,
          })
        );
        dispatch(
          selectedRowOrCurrentRow({
            name: tableDataPos[existingItemIndex].ItemNameTextField,
          })
        );

        showSnackbar(
          "success",
          `item:${
            tableDataPos[existingItemIndex].ItemNameTextField
          },     Add ons: ${[
            ...tableDataPos[existingItemIndex].batch,
            item.Typ,
          ]} `
        );
      }
    } else {
      if (tableDataPos.length <= 0) {
        showSnackbar("error", `please choose item`);
      } else {
        if (item) {
          const addon = [];
          addon.push(item.Typ);

          const newItem = {
            ...currProduct,
            // batch: [...item.batch, item.Typ],
            batch: [...currProduct.batch, item.Typ],
          };
          // setCurrProduct(newItem);

          if (typ === "salesReturn") {
            dispatch(addItemToTableData(newItem));
          } else {
            dispatch(addItemToPos(newItem));
            showSnackbar("success", `Add_on:${newItem.batch.Typ},`);
          }
        }
      }
    }
  };
  const handleAddItem = (item, quantity) => {
    const existingItemIndex = tableDataPos.findIndex(
      (items) => items.ItemC === item.ItemC
    );

    const TaxEnabled = taxUtilitiesListData?.find(
      (data) => data.Typ === "SALES_B2B_AND_B2C"
    );

    if (existingItemIndex !== -1) {
      quantity = quantity ?? 1;

      const newItem = {
        ...item,
        qty: quantity,
        exp_date: dayjs().format("DD/MM/YYYY"),
      };
      newItem.total =
        TaxEnabled.Nam === "YES"
          ? quantity * tableDataPos[existingItemIndex].Rate1
          : ((quantity * tableDataPos[existingItemIndex].Rate1) / 100) *
            tableDataPos[existingItemIndex].Tax1;
      setCurrProduct(newItem);

      // if (item.Typ !== item.SecUnit) {
      if (item.Pack !== 1) {
        handleClickOpen("multiUnit");
      } else {
        quantity = quantity ?? 1;
        const updatedQty = tableDataPos[existingItemIndex].qty + quantity;
        const updatedTotal =
          TaxEnabled.Nam === "YES"
            ? updatedQty * tableDataPos[existingItemIndex].Rate1
            : ((updatedQty * tableDataPos[existingItemIndex].Rate1) / 100) *
                tableDataPos[existingItemIndex].Tax1 +
              tableDataPos[existingItemIndex].Rate1 * updatedQty;

        dispatch(
          updateQtyAndTotal({
            index: existingItemIndex,
            qty: updatedQty,
            total: updatedTotal,
            taxAmt:
              TaxEnabled.Nam === "YES"
                ? (updatedQty * item.Rate1 * item.Tax1) / (100 + item.Tax1)
                : ((updatedQty * item.Rate1) / 100) * item.Tax1,
          })
        );

        dispatch(selectedRowOrCurrentRow({ name: item.ItemNameTextField }));

        showSnackbar(
          "success",
          `Added ${item.ItemNameTextField} - ${updatedQty} X ${item.Rate1} = ${updatedTotal}`
        );
      }
    } else {
      quantity = quantity ?? 1;
      if (item) {
        const newItem = {
          ...item,
          qty: quantity,
          taxAmt:
            TaxEnabled.Nam === "YES"
              ? (quantity * item.Tax1 * item.Rate1) / (100 + item.Tax1)
              : ((quantity * item.Rate1) / 100) * item.Tax1,
          exp_date: dayjs().format("DD/MM/YYYY"),
          batch: isAddOnAlways ? [] : "NIL",
        };
        newItem.total =
          TaxEnabled.Nam === "YES"
            ? newItem.qty * newItem.Rate1
            : ((quantity * newItem.Rate1) / 100) * newItem.Tax1;

        setCurrProduct(newItem);
        // if (newItem.Typ !== newItem.SecUnit) {
        if (newItem.Pack !== 1) {
          handleClickOpen("multiUnit");
        } else if (batchWise === "YES BATCH") {
          handleClickOpen("batchWise");
        } else {
          // If neither condition is met, add the item to the table data
          if (typ === "salesReturn") {
            dispatch(addItemToTableData(newItem));
          } else {
            dispatch(addItemToPos(newItem));
            // showSnackbar(
            //   "success",
            //   `Item:${newItem.ItemNameTextField},Qty:${quantity}`
            // );
          }
        }
      }
    }
  };
  // const handleAddItem = (item, quantity) => {
  //   quantity = quantity ?? 1;
  //   if (item) {
  //     const newItem = {
  //       ...item,
  //       qty: quantity,
  //       taxAmt: ((quantity * item.Rate1) / 100) * item.Tax1,
  //       exp_date: dayjs().format("DD/MM/YYYY"),
  //     };
  //     newItem.total = ((quantity * newItem.Rate1) / 100) * newItem.Tax1;

  //     setCurrProduct(newItem);
  //     if (newItem.Typ != newItem.SecUnit) {
  //       handleClickOpen("multiUnit");
  //     } else if (batchWise == "YES BATCH") {
  //       handleClickOpen("batchWise");
  //     } else {
  //       // If neither condition is met, add the item to the table data
  //       if (typ === "salesReturn") {
  //         dispatch(addItemToTableData(newItem));
  //       } else {
  //         dispatch(addItemToPos(newItem));
  //       }
  //     }
  //   }
  // };

  // Modal open
  const handleClickOpen = (typ) => {
    setModalTyp(typ);

    setOpenModal(true);
  };

  // Modal close
  const handleClose = () => {
    setOpenModal(false);
  };

  // Function to increase the quantity for an item by index
  const increaseQuantity = (index) => {
    setItemQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = (newQuantities[index] || 1) + 1;
      return newQuantities;
    });
  };

  // Function to decrease the quantity for an item by index
  const decreaseQuantity = (index) => {
    setItemQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      const currentQuantity = newQuantities[index] || 1;
      if (currentQuantity > 0) {
        newQuantities[index] = currentQuantity - 1;
      }
      return newQuantities;
    });
  };

  const handleIsProduct = (val) => {
    setIsProductOrAddon(val);
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 0 }}>
          <DialogContent sx={{ p: 0 }}>
            {typ === "salesReturn" ? (
              <>
                {modalTyp === "batchWise" ? (
                  <BatchWindowPos
                    product={currProduct}
                    modalClose={handleClose}
                    batchWise={batchWise}
                    expiry={expiry}
                    typ="salesReturn"
                  />
                ) : (
                  <MultiUnitWindow
                    product={currProduct}
                    expiry={expiry}
                    modalClose={handleClose}
                    typ="salesReturn"
                  />
                )}
              </>
            ) : (
              <>
                {modalTyp === "multiUnit" ? (
                  <MultiUnitWindowPos
                    product={currProduct ? currProduct : {}}
                    modalClose={handleClose}
                    batchWise={batchWise}
                    expiry={expiry}
                    typ="salesBill"
                  />
                ) : (
                  <BatchWindow
                    product={currProduct ? currProduct : {}}
                    expiry={expiry}
                    modalClose={handleClose}
                  />
                )}
              </>
            )}
          </DialogContent>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          flex: "0 0 auto",
          position: "sticky",
          top: 0,
          // background: "white",
          zIndex: 1,
        }}
      >
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={categoryMenuItems || []}
              getOptionLabel={(option) => option.Typ}
              onChange={(e, v) => {
                handelCategory(v.Typ);
              }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  fullWidth
                  size="small"
                  disabled
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              size="small"
              sx={{ width: "100%", pr: 1, pl: 2, my: 2 }}
              id="input-search-profile"
              value={searchName}
              // onChange={(e) => setSearchName(e.target.value)}
              onChange={(e) => handleSearchField(e.target.value)}
              placeholder="Search products"
              startAdornment={
                <InputAdornment position="start">
                  <IconSearch
                    stroke={1.5}
                    size="1rem"
                    color={theme.palette.grey[500]}
                  />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {searchName !== "" ? (
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={clearField}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              }
              aria-describedby="search-helper-text"
            />
          </Grid>
          {/* <Grid item xs={2}>
            <Button variant="contained" onClick={handleSearchField}>
              <IconSearch />
            </Button>
          </Grid> */}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Net Total :</Typography>
              <Typography variant="h4" sx={{ textAlign: "right" }}>
                {netTotal.toFixed(3)}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      {isAddOnAlways && (
        <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                variant={isProductOrAddon ? "contained" : ""}
                onClick={() => handleIsProduct(true)}
                sx={{
                  background: isProductOrAddon ? "rgb(94 53 177)" : "#eeeeee",
                  // background: isProductOrAddon ? "" : "#C0C0C0",
                  color: isProductOrAddon ? "white" : "black",
                  boxShadow: isProductOrAddon ? shadowOutSide : shadowInside,
                  ":hover": {
                    background: "green",
                  },
                }}
                fullWidth
                // disabled={!isProductOrAddon}
              >
                Products
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={!isProductOrAddon ? "contained" : ""}
                onClick={() => handleIsProduct(false)}
                sx={{
                  background: !isProductOrAddon ? "rgb(94 53 177)" : "#eeeeee",
                  // background: !isProductOrAddon ? "" : "#C0C0C0",
                  color: !isProductOrAddon ? "white" : "black",
                  boxShadow: !isProductOrAddon ? shadowOutSide : shadowInside,
                  ":hover": {
                    background: "green",
                  },
                }}
                fullWidth
              >
                Add On
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrevClick}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextClick}
          // disabled={
          //   itemsToRender?.length < itemsPerPage ||
          //   endIndex >= allProducts.length
          // }
          disabled={
            !itemsToRender ||
            itemsToRender?.length < itemsPerPage ||
            !allProducts ||
            endIndex >= allProducts?.length
          }
          endIcon={<ArrowForwardIosIcon />}
        >
          Next
        </Button>
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
          mt: 2,
          borderTop: "1px solid lightgrey", // Optional visual separator
        }}
      >
        <Grid container spacing={1}>
          {/* {itemsToRender.map((item, index) => { */}
          {isProductOrAddon
            ? itemsToRender &&
              Array.isArray(itemsToRender) &&
              itemsToRender?.map((item, index) => {
                return (
                  <Grid item xs={6} sm={4} key={index}>
                    <Card
                      sx={{
                        p: 1,
                        width: "100%",
                        height: "160px",
                        "&:hover": {
                          backgroundColor: "#D3D3D3",
                        },
                      }}
                    >
                      <Box
                        onClick={() =>
                          handleAddItem(item, itemQuantities[index])
                        }
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "80px",
                            objectFit: "contain",
                          }}
                          // src={
                          //   item.photo
                          //     ? // ? item.photo
                          //       item.photo == null ||
                          //       item.photo === "null" ||
                          //       // item.photo.includes("WIZZO")
                          //       regex.test(item.photo)
                          //       ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU"
                          //       : JSON.parse(item.photo).img
                          //     : // console.log(item.photo)
                          //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU"
                          // }
                          // src={
                          //   item.photo
                          //     ? item.photo == null ||
                          //       item.photo === "null" ||
                          //       regex.test(item.photo)
                          //       ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU"
                          //       : (() => {
                          //           try {
                          //             return JSON.parse(item.photo).img;
                          //           } catch (e) {
                          //             console.error("Error parsing JSON:", e);
                          //             return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU";
                          //           }
                          //         })()
                          //     : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU"
                          // }
                          src={
                            item.photo
                              ? item.photo == null ||
                                item.photo === "null" ||
                                regex.test(item.photo)
                                ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU"
                                : (() => {
                                    try {
                                      // console.log(
                                      //   "Attempting to parse item.photo:",
                                      //   item.photo
                                      // ); // Add logging
                                      const parsedPhoto = JSON.parse(
                                        item.photo
                                      );
                                      return (
                                        parsedPhoto.img ||
                                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU"
                                      );
                                    } catch (e) {
                                      console.log("Error parsing JSON:", item);

                                      console.error(
                                        "Error parsing JSON:",
                                        e,
                                        "item.photo:",
                                        item.photo
                                      );
                                      return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU";
                                    }
                                  })()
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU"
                          }
                          alt="zxc"
                        />
                        <p
                          style={{
                            width: "-webkit-fill-available",
                            border: "none",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            margin: "0rem",
                          }}
                        >
                          {item.ItemNameTextField}
                        </p>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="body2" color="text.secondary">
                            Price: <span>{item.Rate1}</span>
                          </Typography>
                          {/* <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            sx={{ color: 'rgb(94 53 177)', p: 0 }}
                                            onClick={() => handleAddItem(item, itemQuantities[index])}
                                            >
                                            <AddCircleIcon />
                                        </IconButton> */}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap={"2px"}
                      >
                        <IconButton
                          color="primary"
                          aria-label="Decrease quantity"
                          component="label"
                          sx={{ color: "rgb(94 53 177)", p: 0 }}
                          onClick={() => decreaseQuantity(index)}
                        >
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                        <Typography variant="body2" color="text.secondary">
                          Qty: {itemQuantities[index] || 1}
                        </Typography>
                        <IconButton
                          color="primary"
                          aria-label="Increase quantity"
                          component="label"
                          sx={{ color: "rgb(94 53 177)", p: 0 }}
                          onClick={() => increaseQuantity(index)}
                        >
                          <NavigateNextIcon />
                        </IconButton>
                      </Box>
                    </Card>
                  </Grid>
                );
              })
            : addOnItems &&
              Array.isArray(addOnItems) &&
              addOnItems?.map((item, index) => {
                const color = colors[index % colors.length];
                return (
                  <Grid item xs={6} sm={4} key={index}>
                    <Box
                      className="button-30"
                      sx={{ backgroundColor: color }}
                      onClick={() => handleAddAddOns(item)}
                    >
                      {item.Typ}
                    </Box>
                  </Grid>
                );
              })}
        </Grid>
      </Box>
    </Box>
  );
}

export default DrawerItems;
