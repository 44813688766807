import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
// import SearchSection from "./SearchSection";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
// assets
import { IconMenu2 } from "@tabler/icons";
// import { homeUrl } from "config";
import TopNavbar from "./TopNavbar/TopNavbar";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  // const handleLogout = async () => {
  //   window.location.href = homeUrl;
  //   // axios(
  //   //   {
  //   //     method: "post",
  //   //     url: baseUrl + "/logout",
  //   //   },
  //   //   { withCredentials: true }
  //   // )
  //   //   .then((res) => {
  //   //     window.location.href = 'http://localhost:5173/'
  //   //   })
  //   //   .catch((err) => {
  //   //     if (err) throw err;
  //   //   });
  // };
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <ButtonBase
          sx={{
            borderRadius: "12px",
            overflow: "hidden",
            display: {
              xs: "block", // Optional: Define for extra small screens
              sm: "block", // Visible on small screens
              md: "none", // Hidden on medium and larger screens
            },
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>
      {/* <Button
        onClick={handleLogout}
        size="samll"
        variant="contained"
        startIcon={<IconHome stroke={1.5} size="1.3rem" />}
        sx={{ ml: 3, background: "#4527a0" }}
      >
        Home
      </Button> */}
      <TopNavbar />
      {/* <SearchSection /> */}
      {/* <Box sx={{ flexGrow: 1, backgroundColor: "yellow" }} /> */}
      <Box sx={{ flexGrow: 1 }} />

      <NotificationSection />
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
