import axios from "axios";
import { pdfjs } from "react-pdf";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

// import LoaderDrop from "src/utils/LoaderDrop";

// import { baseUrl, redirectUrl } from "src/config";

// import { useSnackbar } from "src/context/SnackBarContext";

import PdfPreivew from "./components/PdfPreview";
import ImgPreview from "./components/ImgPreview";
import LoaderDrop from "./components/LoaderDrop";
import { redirectUrl } from "config";
import { baseUrl } from "config";
import { useSnackBar } from "context/snackbarContext";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

function FileUpload({ open, handleClose, setOpen, data, type }) {
  const [files, setFiles] = useState([]); // to store the selected image path
  const [descriptions, setDescriptions] = useState([]); // to store the selected image path
  const [imagePreview, setImagePreview] = useState(""); // to store selected image preview link
  const [pdfPreview, setPdfPreview] = useState(""); // to store pdf preiview link
  const [openPdf, setOpenPdf] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState([]);
  const theme = useTheme();
  const { showSnackbar } = useSnackBar();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (open) {
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const textFieldStyle = {
    ".MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-89iyvt-MuiInputBase-root-MuiOutlinedInput-root ":
      { borderColor: "red" },
  };

  const getData = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/getUploadedFiles`,
        { co_id: data, typ: type },
        {
          withCredentials: true,
        }
      );
      if (response.data) {
        if (response.data.data.length >= 1) {
          setIsUpdate(true);
        } else {
          handleClearAll();
          setIsUpdate(false);
        }
        const newDescriptions = Array.from(
          { length: JSON.parse(response.data.data[0].files).length },
          (_, index) => JSON.parse(response.data.data[0].files)[index].notes
        );
        const dataGot = JSON.parse(response.data.data[0].files);
        console.log("iiiiiiiiiiiiiiiiiiii", dataGot);

        const dataDeleteId = dataGot.map((dataID) => dataID.fileId);
        if (dataDeleteId.length > 0) {
          setFiles(dataGot);
        } else {
          setFiles([]);
        }
        setDeletedItemId(dataDeleteId);
        setDescriptions(newDescriptions);
      }
    } catch (err) {
      // if (err.response.status === 403 || err.response.status === 401) {
      //   window.location.href = redirectUrl;
      // } else {
      showSnackbar("error", "Something went wrong");
      // }
    }
  };

  // File set into state function
  const fileSelectedHandler = (event) => {
    const selectedFiles = Array.from(event.target.files);
    handleFiles(selectedFiles);
  };

  // Drag and drop file
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Drag and drop file
  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFiles = Array.from(e.dataTransfer.files);
    handleFiles(selectedFiles);
  };

  // Function to handle files and filter by type
  const handleFiles = (selectedFiles) => {
    const filteredFiles = selectedFiles.filter(
      (file) =>
        file.type === "application/pdf" || file.type.startsWith("image/")
    );

    setFiles([...files, ...filteredFiles]);

    // const newDescriptions = Array.from({ length: filteredFiles.length }, (_, index) => ({
    //   [`description${index}`]: '',
    // }));
    const newDescriptions = Array.from(
      { length: filteredFiles.length },
      (_, index) => ""
    );
    setDescriptions([...descriptions, ...newDescriptions]);
    // setDescriptions(...descriptions, '');
  };
  // handle File remove
  const handleFileRemove = (value, index) => {
    // removing the files
    const dupplicateFiles = [...files];
    dupplicateFiles.splice(index, 1);
    setFiles(dupplicateFiles);

    // removing the description
    const dupplicateDescription = [...descriptions];
    dupplicateDescription.splice(index, 1);
    setDescriptions(dupplicateDescription);
  };

  // handle clear btn
  const handleClearAll = () => {
    setFiles([]);
    setDescriptions([]);
  };

  const handleOpenPreview = async (value) => {
    setLoading(true);
    let extension;
    // work while the already existing link is there
    if (value.img) {
      extension = value.img.split(".").pop().toLowerCase();
    }

    // checking the given data have pdf extention or not
    const isPDF = extension === "pdf";

    if (isPDF) {
      // it work if the given data by api  have pdf extention yo preview
      const response = await fetch(value.img);
      const blob = await response.blob();
      const pdfLink = URL.createObjectURL(blob);
      setPdfPreview(pdfLink);
      setOpenPdf(true);
      setLoading(false);
    } else if (value.type === "application/pdf") {
      // newly added file from our pc  have pdf  preview
      const pdfLink = URL.createObjectURL(value);
      setPdfPreview(pdfLink);
      setOpenPdf(true);
      setLoading(false);
    } else if (!value.img) {
      // newly added file  from our pc have image  preview
      const imageLink = URL.createObjectURL(value);
      setImagePreview(imageLink);
      setOpenImg(true);
      setLoading(false);
    } else {
      // it work if the given data by api  have img  preview
      setImagePreview(value.img);
      setOpenImg(true);
      setLoading(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "60%",
    bgcolor: "background.paper",
    borderRadius: "1rem",
    boxShadow: 24,
    p: isMobile ? 2 : 4,
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    // const valuesArray = descriptions.map((obj) => Object.values(obj)[0]);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("data", descriptions);
    formData.append("co_id", data);
    formData.append("typ", type);

    try {
      const response = await axios.post(`${baseUrl}/uploadFiles`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      if (response.data.success) {
        setOpen(false);
        setLoading(false);
        showSnackbar("success", response.data.message);
        handleClearAll();
        getData();
      }
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 401) {
        window.location.href = redirectUrl;
      } else {
        showSnackbar("error", "Something went wrong");
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    // extractig  newly selected files from the files state
    const filteredNewFilesOnly = files?.filter((item) => !item.img);

    // extracting the already exsting link given from the database which stored in the files state
    const filteredGivenData = files?.filter((item) => item.img);

    // converting the already exsting link given only inot file or Blob
    const newArr = await Promise.all(
      filteredGivenData.map(async (item, index) => {
        let metadata;
        const responsed = await fetch(item.img);
        const datas = await responsed.blob();
        if (item.img.split(".").pop().toLowerCase() === "pdf") {
          metadata = {
            type: "application/pdf",
          };
        } else {
          metadata = {
            type: `image/${item.img.split(".").pop().toLowerCase()}`,
          };
        }

        return new File(
          [datas],
          `${index}.${item.img.split(".").pop().toLowerCase()}`,
          metadata
        );
      })
    );

    // concat the both the newly selected file and the already existing image converted into files into a single array
    const combinedArray = filteredNewFilesOnly.concat(newArr);
    // setting id of delete items
    const formData = new FormData();

    // setting the
    combinedArray.forEach((fi) => {
      formData.append("files", fi);
    });

    formData.append("data", descriptions);
    formData.append("co_id", data);
    formData.append("typ", type);
    formData.append("deletedFiles", JSON.stringify(deletedItemId));
    try {
      const response = await axios.put(`${baseUrl}/updateFiles`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      if (response.data.success) {
        setOpen(false);
        setLoading(false);
        showSnackbar("success", response.data.message);
        // handleClearAll();
        getData();
      }
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 401) {
        window.location.href = redirectUrl;
      } else {
        showSnackbar("error", "Something went wrong");
      }
    }
  };

  const handleFilesName = (e, index) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[index] = e.target.value;
    setDescriptions(updatedDescriptions);
  };

  return (
    <>
      <ImgPreview
        imgLink={imagePreview}
        open={openImg}
        setOpen={setOpenImg}
        loading={loading}
      />
      <PdfPreivew
        pdfFile={pdfPreview}
        openPdf={openPdf}
        setOpenPdf={setOpenPdf}
        loading={loading}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <LoaderDrop start={loading} />
          <Grid container>
            <Grid item xs={12} sx={{ height: "50vh", overflow: "auto" }}>
              {files.length <= 0 && (
                <div
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  style={{
                    display: "flex",
                    border: "2px dashed #ccc",
                    padding: "20px",
                    height: "90%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h6">DRAG & DROP</Typography>
                </div>
              )}
              {files && (
                <List>
                  {files.map((item, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      sx={{
                        backgroundColor: "#E8E8E8",
                        mb: "0.2rem",
                        borderRadius: "0.5rem",
                        p: 0.5,
                      }}
                      spacing={0}
                    >
                      <ListItem sx={{ maxWidth: "85%", overflow: "hidden" }}>
                        <TextField
                          size="small"
                          // inputProps={{ style: textFieldStyle }}
                          sx={textFieldStyle}
                          // value={descriptions[index][`description[${index}]`]}
                          value={descriptions[index]}
                          // value={descriptions[index].description}
                          onChange={(e) => handleFilesName(e, index)}
                        />
                      </ListItem>
                      <Button
                        endIcon={<Icon icon="material-symbols:preview" />}
                        onClick={() => handleOpenPreview(item)}
                        sx={{ mr: { xs: 0, md: 2 } }}
                      >
                        {isMobile ? "" : "preview"}
                      </Button>
                      <IconButton onClick={() => handleFileRemove(item, index)}>
                        <Icon
                          icon="material-symbols:close-rounded"
                          color="red"
                        />
                      </IconButton>
                    </Stack>
                  ))}
                </List>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<Icon icon="material-symbols:upload-sharp" />}
                  fullWidth
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    onChange={fileSelectedHandler}
                    multiple
                    accept="image/*,.pdf"
                  />
                </Button>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button
                  component="label"
                  variant="contained"
                  color="error"
                  fullWidth
                  onClick={handleClearAll}
                >
                  Clear All
                </Button>
              </Grid>

              <Grid item xs={6} md={4}>
                <Button
                  component="label"
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={isUpdate ? handleUpdate : handleUpload}
                  disabled={files.length < 1}
                >
                  {isUpdate ? "update" : "submit"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
FileUpload.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setOpen: PropTypes.func,
  data: PropTypes.object,
  type: PropTypes.string,
};

export default FileUpload;
