const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "/",
  defaultPath: "/dashboard",
  fontFamily: `'Inter', sans-serif`,
  borderRadius: 10,
};

const storedLgpath = localStorage.getItem("lgpath");

// export const baseUrl = "http://localhost:3008";
// export const homeUrl = "http://localhost:5173/";

// export const redirectUrl = () => {
//   window.location.href = storedLgpath
//     ? `http://localhost:5173${storedLgpath}`
//     : "http://localhost:5173/";
// };

// ----------------------------PRODUCTION URLS ------------------------------

// export const baseUrl = "https://productionbackend.wizzo.in";
// export const homeUrl = " https://productioncloud.wizzo.in/";

// export const redirectUrl = () => {
//   window.location.href = storedLgpath
//     ? ` https://productioncloud.wizzo.in${storedLgpath}`
//     : `https://productioncloud.wizzo.in/`;
// };

// ----------------------------------------------------------
// ----------------------------saudi URLS ------------------------------

// export const baseUrl = "https://cloud-backend.wizzoit.com";
// export const homeUrl = " https://cloud.wizzoit.com/";

// export const redirectUrl = () => {
//   window.location.href = storedLgpath
//     ? ` https://cloud.wizzoit.com${storedLgpath}`
//     : `https://cloud.wizzoit.com/`;
// };

// ----------------------------------------------------------

// --------------------CLIENT SERVER URLS -------------------------

export const baseUrl = "https://server.wizzo.in";
export const homeUrl = "https://one.wizzo.in/";
export const redirectUrl = storedLgpath
  ? `https://one.wizzo.in${storedLgpath}`
  : "https://one.wizzo.in/";

// ---------------------------------------------

// export const baseUrl = "https://server.almaccloudpos.wizzosaudi.com";
// export const homeUrl = "https://almaccloudpos.wizzosaudi.com/";

// export const redirectUrl = () => {
//   window.location.href = storedLgpath
//     ? `https://almaccloudpos.wizzosaudi.com${storedLgpath}`
//     : "https://almaccloudpos.wizzosaudi.com/";
// };

// ---------------------------------------------

// export const baseUrl = 'https://integratedpos.wizzogroup.com/server';
// export const linkUrl = 'https://integratedpos.wizzogroup.com/login';
// export const baseUrl = 'https://almaccloudpos.wizzosaudi.com/server';
// export const linkUrl = 'https://almaccloudpos.wizzosaudi.com/login';
// export const baseUrl = "https://3.111.0.148:49205/server";
// export const linkUrl = "https://3.111.0.148:49203/login";

export default config;
