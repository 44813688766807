import { combineReducers } from "redux";

// Reducer imports
import customizationReducer from "./customizationReducer";
import tableDataSlice from "../views/Sales-Sections/salesReturn/slice/tableDataSlice";
import returnHeaderSlice from "../views/Sales-Sections/salesReturn/slice/returnHeaderSlice";
import discAndTaxSlice from "views/Sales-Sections/salesReturn/slice/discAndTaxSlice";
import posHeaderSlice from "views/Sales-Sections/salesPos/slice/posHeaderSlice";
import discAndTaxPosSlice from "views/Sales-Sections/salesPos/slice/discAndTaxPosSlice";
import tableDataPosSlice from "views/Sales-Sections/salesPos/slice/tableDataPosSlice";
import commonSlice from "commonSlice/commonSlice";

//purchase slice
import puchaseHeaderSlice from "views/Purchase-Sections/purchase/slice/puchaseHeaderSlice";
import purchaseDiscAndTaxSlice from "views/Purchase-Sections/purchase/slice/purchaseDiscAndTaxSlice";
import purchaseTableDataSlice from "views/Purchase-Sections/purchase/slice/purchaseTableDataSlice";

//purchase slice
import puchaseImportHeaderSlice from "views/Purchase-Sections/purchase Import/slice/puchaseImportHeaderSlice";
import purchaseImportDiscAndTaxSlice from "views/Purchase-Sections/purchase Import/slice/purchaseImportDiscAndTaxSlice";
import purchaseImportTableDataSlice from "views/Purchase-Sections/purchase Import/slice/purchaseImportTableDataSlice";

//purchaseOrder slice
import puchaseOrdertHeaderSlice from "views/Purchase-Sections/purchaseOrder/slice/puchaseOrderHeaderSlice";
import purchaseOrderDiscAndTaxSlice from "views/Purchase-Sections/purchaseOrder/slice/purchaseOrderDiscAndTaxSlice";
import purchaseOrderTableDataSlice from "views/Purchase-Sections/purchaseOrder/slice/purchaseOrderTableDataSlice";

//purchaseReturn slice
import puchaseReturnHeaderSlice from "views/Purchase-Sections/purchaseReturn/slice/puchaseReturnHeaderSlice";
import purchaseReturnDiscAndTaxSlice from "views/Purchase-Sections/purchaseReturn/slice/purchaseReturnDiscAndTaxSlice";
import purchaseReturnTableDataSlice from "views/Purchase-Sections/purchaseReturn/slice/purchaseReturnTableDataSlice";

//products
import productDataSlice from "./apis";
import discAndTaxPosSliceB2C from "views/Sales-Sections/salesPosB2C/slice/discAndTaxPosSliceB2C";
import tableDataPosSliceB2C from "views/Sales-Sections/salesPosB2C/slice/tableDataPosSliceB2C";
import posHeaderSliceB2C from "views/Sales-Sections/salesPosB2C/slice/posHeaderSliceB2C";

//orderForm
import discAndTaxOrderFormSlice from "views/Sales-Sections/orderForm/slice/discAndTaxOrderFormSlice";
import tableDataOrderFormSlice from "views/Sales-Sections/orderForm/slice/tableDataOrderFormSlice";
import posHeaderOrderFormSlice from "views/Sales-Sections/orderForm/slice/posHeaderOrderFormSlice";
// Combine reducers
const reducer = combineReducers({
  common: commonSlice,
  // products

  customization: customizationReducer,

  tableData: tableDataSlice,
  returnHeader: returnHeaderSlice,
  discAndTax: discAndTaxSlice,

  // sales pos reducers
  posHeader: posHeaderSlice,
  discAndTaxPos: discAndTaxPosSlice,
  tableDataPos: tableDataPosSlice,

  // sales pos reducers
  posHeaderB2C: posHeaderSliceB2C,
  discAndTaxPosB2C: discAndTaxPosSliceB2C,
  tableDataPosB2C: tableDataPosSliceB2C,

  //order form reducers
  posHeaderOrderForm: posHeaderOrderFormSlice,
  discAndTaxPosOrderForm: discAndTaxOrderFormSlice,
  tableDataOrderForm: tableDataOrderFormSlice,

  //purchase reducers
  purchaseHeader: puchaseHeaderSlice,
  purchaseDiscAndTax: purchaseDiscAndTaxSlice,
  purchaseTableData: purchaseTableDataSlice,

  //purchase import reducers
  puchaseImportHeader: puchaseImportHeaderSlice,
  purchaseImportDiscAndTax: purchaseImportDiscAndTaxSlice,
  purchaseImportTableData: purchaseImportTableDataSlice,

  //purchaseReturn reducers
  purchaseReturnHeader: puchaseReturnHeaderSlice,
  purchaseReturnDiscAndTax: purchaseReturnDiscAndTaxSlice,
  purchaseReturnTableData: purchaseReturnTableDataSlice,

  //purchaseOrder reducers
  puchaseOrderHeader: puchaseOrdertHeaderSlice,
  purchaseOrderDiscAndTax: purchaseOrderDiscAndTaxSlice,
  purchaseOrderTableData: purchaseOrderTableDataSlice,

  //allProduct
  ProductsData: productDataSlice,
});

export default reducer;
