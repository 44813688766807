import { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { Menu, Button, MenuItem, Container } from "@mui/material";

// import { useResponsive } from "src/hooks/use-responsive";

// import { HEADER } from "./config-layout";
import { homeUrl } from "config";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import navConfig from "./navMenuItems";

import menuItem from "../../../../menu-items";
import { store } from "store";

function TopNavbar({ onOpenNav }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  // const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  // const [activeSubMenu, setActiveSubMenu] = useState(null);
  const state = store.getState();
  const isAccountsShow = state.common.isAccountsShow;

  const handleMainClick = (event, idx) => {
    if (openMenu === idx) {
      // Close menu if it's already open
      setAnchorEl(null);
      setOpenMenu(null);
      // setSubMenuAnchorEl(null);
      // setActiveSubMenu(null);
    } else {
      // Open selected menu
      setAnchorEl(event.currentTarget);
      setOpenMenu(idx);
      // setSubMenuAnchorEl(null);
      // setActiveSubMenu(null);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
    // setSubMenuAnchorEl(null);
    // setActiveSubMenu(null);
  };

  const handleSubMenuClick = (event, child, index) => {
    if (child.children) {
      // setSubMenuAnchorEl(event.currentTarget);
      // setActiveSubMenu(index);
    } else if (child.path) {
      // navigate(child.path);
      handleMenuClose();
    }
  };

  const toHome = () => {
    window.location.href = homeUrl;
  };

  return (
    <Toolbar
      sx={{
        height: 1,
        px: { lg: 5 },
      }}
    >
      <Container maxWidth="xl">
        <Stack direction="row" gap={1}>
          <Button
            onClick={toHome}
            variant="contained"
            sx={{ width: "125px", borderRadius: "50px", background: "#4527a0" }}
          >
            Home
          </Button>

          {/* <Stack
            sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            direction="row"
            gap={1}
          >
            {menuItem.map((value, idx) => (
              if (!isAccountsShow && item.id === "accounts") {
                return null; // Return null to exclude it from rendering
              }
              <Box key={idx}>
                <Button
                  variant="outlined"
                  // color="success"
                  startIcon={value.icon}
                  sx={{
                    width: "125px",
                    borderRadius: "50px",
                    borderColor: "#4527a0",
                    color: "#4527a0",
                  }}
                  onClick={(event) =>
                    value.url
                      ? navigate(`${value.url}`)
                      : handleMainClick(event, idx)
                  }
                >
                  {value.title}
                </Button>
                <Menu
                  open={openMenu === idx}
                  anchorEl={anchorEl}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {value.children?.map((child, index) => (
                    <MenuItem
                      key={index}
                      onClick={(event) => {
                        handleSubMenuClick(event, child, index);
                      }}
                    >
                      {child.url ? (
                        <Link
                          to={child.url}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            width: "100%",
                          }}
                          onClick={handleMenuClose}
                        >
                          {child.title}
                        </Link>
                      ) : (
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          gap={2}
                          alignItems="center"
                        >
                          {child.title}

                          <ChevronRightIcon stroke={1.5} size="1.3rem" />
                        </Stack>
                      )}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ))}
          </Stack> */}
          <Stack
            sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            direction="row"
            gap={1}
          >
            {menuItem.map((value, idx) => {
              // Conditionally exclude "accounts" if isAccountsShow is false
              if (!isAccountsShow && value.id === "accounts") {
                return null; // Skip rendering
              }

              return (
                <Box key={idx}>
                  <Button
                    variant="outlined"
                    startIcon={value.icon}
                    sx={{
                      width: "125px",
                      borderRadius: "50px",
                      borderColor: "#4527a0",
                      color: "#4527a0",
                    }}
                    onClick={
                      (event) =>
                        value.url
                          ? navigate(`${value.url}`) // Navigate directly if URL is defined
                          : handleMainClick(event, idx) // Toggle menu if no URL
                    }
                  >
                    {value.title}
                  </Button>
                  {/* Dropdown Menu */}
                  <Menu
                    open={openMenu === idx}
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {value.children?.map((child, index) => (
                      <MenuItem
                        key={index}
                        onClick={(event) => {
                          handleSubMenuClick(event, child, index); // Handle submenu clicks
                        }}
                      >
                        {child.url ? (
                          <Link
                            to={child.url}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              width: "100%",
                            }}
                            onClick={handleMenuClose}
                          >
                            {child.title}
                          </Link>
                        ) : (
                          <Stack
                            direction="row"
                            width="100%"
                            justifyContent="space-between"
                            gap={2}
                            alignItems="center"
                          >
                            {child.title}
                            <ChevronRightIcon stroke={1.5} size="1.3rem" />
                          </Stack>
                        )}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </Container>
    </Toolbar>
    // </AppBar>
  );
}

export default TopNavbar;

TopNavbar.propTypes = {
  onOpenNav: PropTypes.func,
};
